define("discourse/plugins/discourse-char-coordinator/discourse/helpers/alpha-char-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(num) {
    if (num == 0) return '#';
    const alpha = String.fromCharCode(num + 64);
    return alpha;
  }
});