define("discourse/plugins/discourse-char-coordinator/discourse/controllers/edit-ruleset", ["exports", "discourse/models/user", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/plugins/discourse-char-coordinator/lib/TreeHandler"], function (_exports, _user, _ajax, _ajaxError, _TreeHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBUG = false;
  const init = function () {
    this._super();
    this.permissionsCheck(_user.default.current());
    if (this.get('not_logged_in')) return;
    this.fetchAllTrees().then(response => {
      this.set('tree_list', response);
    });
    this.fetchAllRulesets().then(response => {
      this.set('ruleset_list', response);
    });
  };
  const actions = {
    applyAllRulesets() {
      if (DEBUG) console.log("-- Applying all rulesets.");
      (0, _ajax.ajax)('/run_all_rulesets/', {
        type: "PUT"
      }).then(response => {
        if (DEBUG) console.log("--", response);
      }).catch(_ajaxError.popupAjaxError);
    },
    applyRuleset() {
      const ruleset_id = $('#ruleset_id').val();
      if (DEBUG) console.log("-- Applying ruleset ID:", ruleset_id);
      (0, _ajax.ajax)('/run_ruleset/' + ruleset_id, {
        type: "PUT"
      }).then(response => {
        if (DEBUG) console.log("--", response);
      }).catch(_ajaxError.popupAjaxError);
    },
    createRuleset() {
      let ruleset_id = this.get('id');

      // create a random ID for ruleset
      if (!ruleset_id) {
        //let a = Math.random().toString(36).substring(2,6);
        //let b = Math.random().toString(36).substring(2,6);
        //ruleset_id = a + b;
        ruleset_id = Date.now();
      }
      if (!this.get('rule_map')) {
        if (DEBUG) console.log('-- New ruleset, start with a single node');
        this.set('rule_map', "[{ \"tree_id\": 0, \"node_id\": -1, \"co_only\": null }]");
      } else {
        // make sure to update ember app state!!
        // this.set('rule_map', $('#rule_map').val());
      }
      const ruleset_data = this.store.createRecord('ruleset', {
        id: ruleset_id,
        name: this.get('name'),
        rule_map: this.get('rule_map')
      });
      ruleset_data.save().then(result => {
        let ruleset_exists = false;
        for (var i = 0; i < this.ruleset_list.length; i++) {
          if (this.ruleset_list[i].id == result.target.id) {
            this.ruleset_list.removeAt(i);
            this.ruleset_list.pushObject(result.target);
            ruleset_exists = true;
          }
        }
        if (!ruleset_exists) this.ruleset_list.pushObject(result.target);
      }).catch(console.error);
    },
    loadRuleset(ruleset) {
      this.set('id', ruleset.id);
      this.set('name', ruleset.name);
      this.set('rule_map', ruleset.rule_map);
    },
    deleteRuleset(ruleset) {
      if (confirm("You sure? This action cannot be undone.")) {
        (0, _ajax.ajax)('/rulesets/' + ruleset.id, {
          type: "DELETE"
        }).then(response => {
          this.ruleset_list.removeObject(ruleset);
        }).catch(_ajaxError.popupAjaxError);
      }
    },
    renderRuleMap() {
      const map_json = JSON.parse($('#rule_map').val());
      let map_items = Object.values(map_json);
      this.set('current_map_rendered', map_items);
    },
    newRule() {
      //console.log("creating new node");
      let map_json = JSON.parse($('#rule_map').val());
      const new_node = {
        "tree_id": '#',
        "node_id": '#',
        "co_only": false,
        "children_only": false,
        "exclude_self": false
      };
      map_json.pushObject(new_node);
      $('#rule_map').val(JSON.stringify(map_json));
    },
    writeRules() {
      const rule_rows = $('ul#ruleset_edit_rows li.rule_row').toArray();
      let ruleset_json = [];
      rule_rows.forEach(item => {
        const rule_params = $(item).children().toArray();
        let rule_json = {};
        rule_params.forEach(param => {
          const name = $(param).attr('name');
          if (name == 'tree_id' || name == 'node_id') rule_json[name] = $(param).val();else if (!name) {
            // button  :roll:
          } else rule_json[name] = $(param).prop('checked');
        });
        ruleset_json.push(rule_json);
      });
      $('#rule_map').val(JSON.stringify(ruleset_json));
      this.set('rule_map', JSON.stringify(ruleset_json));
    },
    deleteRule(index) {
      let current_map = this.get('current_map_rendered');
      current_map = current_map.removeAt(index);
    }
  };
  var _default = _exports.default = (0, _TreeHandler.TreeHandler)({
    init,
    actions
  });
});