define("discourse/plugins/discourse-char-coordinator/discourse/controllers/admin-plugins-dcc", ["exports", "discourse/plugins/discourse-char-coordinator/lib/AccessGenerics", "discourse/models/user"], function (_exports, _AccessGenerics, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const init = function () {
    this._super();
    this.fetchPluginIntro().then(response => {
      this.set('plugin_intro', response);
    });
    this.fetchPluginStaffIntro().then(response => {
      this.set('plugin_staff_intro', response);
    });
    this.fetchProfileCss().then(response => {
      this.set('profile_css', response);
    });
    this.fetchOrgchartCss().then(response => {
      this.set('orgchart_css', response);
    });
    this.fetchFactionRanks().then(response => {
      this.set('faction_ranks', response);
    });
    this.fetchRestrictedFields().then(response => {
      this.set('restricted_fields', response);
    });
    this.fetchCdirStatuses().then(response => {
      this.set('cdir_statuses', response);
    });
    this.fetchPlayerGroup().then(response => {
      this.set('player_group', response);
    });
  };
  const actions = {
    saveAdminSetting(generic_name) {
      let data = this.get(generic_name);
      const record = this.store.createRecord('generic', {
        id: generic_name,
        value: data
      });
      record.save().then(result => {
        // console.log(result);
      }).catch(console.error);
    }
  };
  var _default = _exports.default = (0, _AccessGenerics.AccessGenerics)({
    init,
    actions
  });
});