define("discourse/plugins/discourse-char-coordinator/discourse/admin-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  * special admin version...
  * route -> url/path
  */
  var _default = _exports.default = {
    resource: 'admin.adminPlugins',
    path: '/plugins',
    map() {
      this.route('dcc');
    }
  };
});