define("discourse/plugins/discourse-char-coordinator/discourse/routes/profile", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
  * route -> template/handlebars
  */
  var _default = _exports.default = _discourse.default.extend({
    /*renderTemplate() {
      // Renders the template '../templates/profile.hbs'
      //this.render('profile');
    },*/

    queryParams: {
      v: {
        refreshModel: true
      }
    },
    model(params) {
      return params;
    },
    afterModel: function (model, transition) {
      this.controllerFor('profile').send('initChar', model.v);
    }
  });
});