define("discourse/plugins/discourse-char-coordinator/discourse/routes/orgchart", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
  * route -> template/handlebars
  */
  var _default = _exports.default = _discourse.default.extend({
    // renderTemplate() {
    //   // Renders the template '../templates/orgchart.hbs'
    //   this.render('orgchart');
    // },

    queryParams: {
      v: {
        refreshModel: true
      }
    },
    model(params) {
      return params;
    },
    afterModel: function (model, transition) {
      this.controllerFor('orgchart').send('initOrgchart', model.v);
    }
  });
});