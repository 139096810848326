define("discourse/plugins/discourse-char-coordinator/discourse/controllers/dcc", ["exports", "discourse/plugins/discourse-char-coordinator/lib/AccessGenerics", "discourse/models/user", "@ember/template"], function (_exports, _AccessGenerics, _user, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const init = function () {
    this._super();
    this.permissionsCheck(_user.default.current());
    if (this.get('not_logged_in')) return;
    this.fetchPluginIntro().then(response => {
      this.set('plugin_intro', (0, _template.htmlSafe)(response));
    });
    this.fetchPluginStaffIntro().then(response => {
      this.set('plugin_staff_intro', (0, _template.htmlSafe)(response));
    });
  };
  const actions = {};
  var _default = _exports.default = (0, _AccessGenerics.AccessGenerics)({
    init,
    actions
  });
});