define("discourse/plugins/discourse-char-coordinator/lib/TreeHandler", ["exports", "ember", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _ember, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TreeHandler = void 0;
  const TreeHandler = _ref => {
    let {
      init,
      actions
    } = _ref;
    return _ember.default.Controller.extend({
      init,
      permissionsCheck(current_user) {
        if (!current_user) {
          this.set('not_logged_in', true);
          return;
        }

        // find out if the current user is Admin or Moderator
        if (current_user.admin) this.set('user_is_staff', true);
        if (current_user.moderator && this.siteSettings.dcc_allow_mods_access) this.set('user_is_staff', true);
      },
      fetchOrgchartCss() {
        // sigh for duplicate
        //console.log("called generics:fetchOrgchartCss");

        return (0, _ajax.ajax)('/genfetch_orgchart_css', {
          type: "GET"
        }).then(response => {
          return response;
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchFactionRanks() {
        // oh.
        //console.log("called fetchFactionRanks");

        return (0, _ajax.ajax)('/genfetch_faction_ranks', {
          type: "GET"
        }).then(response => {
          return response;
        }).catch(_ajaxError.popupAjaxError);
      },
      sanitizeForHTML(str) {
        const sanitized = str.replace(/[ ,'\/#!$%\^&\*;:{}=`~()]/g, "");
        return sanitized;
      },
      fetchTree(tree_id) {
        return (0, _ajax.ajax)('/orgchart/view/' + tree_id, {
          type: "GET"
        }).then(response => {
          return response;
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchTreePersonnel(tree_id) {
        return (0, _ajax.ajax)('/orgchart/view-p/' + tree_id, {
          type: "GET"
        }).then(response => {
          return response;
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchAllTrees() {
        return (0, _ajax.ajax)('/trees_fetch_all', {
          type: "GET"
        }).then(response => {
          let tree_list = [];
          response.forEach(tree => {
            tree_list.pushObject(JSON.parse(tree.value));
          });
          return tree_list;
        }).catch(_ajaxError.popupAjaxError);
      },
      reverseAncestry(map_json) {
        let child_map = {};

        // departments first
        map_json.forEach(node => {
          let parent_id = node.parent;
          if (parent_id != "") {
            if (child_map[parent_id] == null) child_map[parent_id] = [];
            child_map[parent_id].push(node);
          }
        });
        return child_map;
      },
      mergeMappings(map_json, child_map) {
        let complete_tree_map = [];
        map_json.forEach(node => {
          if (child_map[node.id]) {
            // only sort if there's children TO sort
            node['child_map'] = child_map[node.id];

            //console.log("BEFORE:", child_map[node.id]);
            child_map[node.id].sort(this.comparePriority);
            //console.log("AFTER:", child_map[node.id]);
          }
          complete_tree_map.push(node);
        });
        return complete_tree_map;
      },
      getTreeRoots(complete_tree_map) {
        let roots = [];
        complete_tree_map.forEach(node => {
          if (!node.parent) roots.push(node);
        });
        return roots;
      },
      comparePriority(a, b) {
        // console.log("comparing " + a.name + " priority: " + a.priority);
        // console.log(b.name + " priority: " + b.priority);

        // comparing two nodes
        if (!a.user_id && !b.user_id) {
          if (!a.priority && !b.priority) {
            // no priorities set, alphabetize, instead
            if (a.name < b.name) return -1;else if (a.name > b.name) return 1;
          } else if (!a.priority) return 1;else if (!b.priority) return -1;else {
            if (a.priority != b.priority) return Math.sign(b.priority - a.priority);
          }

          // console.log("made it out... now what?");
        }

        // put chars in front of nodes
        if (!a.user_id) return 1;
        if (!b.user_id) return -1;

        // node_co trumps all
        if (a.node_co && b.node_co) {
          // unless there's more than one of them qq
          return Math.sign(a.node_co_priority - b.node_co_priority);
        }
        if (a.node_co) return -1;
        if (b.node_co) return 1;

        // comparing two chars
        if (a.priority != b.priority) return Math.sign(b.priority - a.priority);

        // console.log("char priorities are equal");
        if (a.l_name < b.l_name) return -1;else if (a.l_name > b.l_name) return 1;else {
          // last names are the same, compare first names
          if (a.f_name < b.f_name) return -1;else if (a.f_name > b.f_name) return 1;
        }
        return 0;
      },
      fetchAllRulesets() {
        return (0, _ajax.ajax)('/rulesets_fetch_all', {
          type: "GET"
        }).then(response => {
          //console.log(response);

          let ruleset_list = [];
          response.forEach(ruleset => {
            ruleset_list.pushObject(JSON.parse(ruleset.value));
          });
          return ruleset_list;
        }).catch(_ajaxError.popupAjaxError);
      },
      actions
    });
  };
  _exports.TreeHandler = TreeHandler;
});