define("discourse/plugins/discourse-char-coordinator/discourse/controllers/components/char-form", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    init() {
      this._super();
    },
    actions: {
      createChar() {
        this.attrs.createChar();
      },
      clearForm() {
        this.attrs.clearForm();
      },
      clearColorField(field) {
        this.attrs.clearColorField(field);
      },
      loadRanksDropdown(faction) {
        this.attrs.loadRanksDropdown(faction);
      },
      setThisRank(rank) {
        this.attrs.setThisRank(rank);
      },
      loadNodeIdDropdown(tree_id) {
        this.attrs.loadNodeIdDropdown(tree_id);
      },
      setThisNodeId(node_id) {
        this.attrs.setThisNodeId(node_id);
      },
      verifyCharTag(char_tag) {
        this.attrs.verifyCharTag(char_tag);
      },
      verifyCharId(char_id) {
        this.attrs.verifyCharId(char_id);
      }
    }
  });
});