define("discourse/plugins/discourse-char-coordinator/discourse/controllers/profile", ["exports", "discourse/plugins/discourse-char-coordinator/lib/AccessGenerics", "discourse/models/user", "@ember/template"], function (_exports, _AccessGenerics, _user, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const init = function () {
    this._super();
    this.fetchProfileCss().then(response => {
      this.set('profile_css', response);
    });
    if (_user.default.current()) this.set('user_logged_in', true);
  };
  const actions = {
    initChar(char_id) {
      this.set('show_general', false);
      this.set('show_demographics', false);
      this.set('show_biometrics', false);
      this.set('show_bio_details', false);
      const char_profile = this.fetchChar(char_id);
      this.fetchChar(char_id).then(char_profile => {
        this.set('active_profile', []);
        if (!jQuery.isEmptyObject(char_profile)) {
          this.active_profile.pushObject(char_profile);
          this.fetchUsernameFromId(char_profile.user_id).then(user => {
            if (user.username) this.set('username', user.username);
          });

          // translate tree IDs
          if (char_profile.tree_id) {
            this.fetchTree(char_profile.tree_id).then(tree => {
              if (tree.name) {
                this.set('tree_id_name', tree.name);
                const map_json = JSON.parse(tree.map);
                if (map_json[char_profile.node_id]) {
                  this.set('node_id_name', map_json[char_profile.node_id].name);
                  const node = map_json[char_profile.node_id];
                  if (node.station == -1) this.set('station_name', null);else if (map_json[node.station]) this.set('station_name', map_json[node.station].name);
                }
              } else {
                // non-official tree_id in use
                this.set('tree_id_name', char_profile.tree_id);
                this.set('node_id_name', char_profile.node_id);
              }
            });
          }

          // need rank picture, sigh
          this.fetchFactionRanks().then(response => {
            const faction_json = JSON.parse(response)[char_profile.faction];
            faction_json.forEach(rank => {
              if (rank.abbr == char_profile.rank) {
                this.set('rank_image_link', rank.image_link);
                this.set('rank_full', rank.name);
              }
            });
          });

          // set... colors
          this.set('char_skin_color', "background-color:" + (0, _template.htmlSafe)(char_profile.skin_color));
          this.set('char_eye_color', "background-color:" + (0, _template.htmlSafe)(char_profile.eye_color));
          this.set('char_hair_color', "background-color:" + (0, _template.htmlSafe)(char_profile.hair_color));

          // show section boolean checks
          if (char_profile.f_name || char_profile.m_name || char_profile.l_name || char_profile.tree_id || char_profile.node_id || char_profile.service_num || char_profile.job_title) {
            this.set('show_general', true);
          }
          if (char_profile.dob || char_profile.pob || char_profile.species || char_profile.gender) {
            this.set('show_demographics', true);
          }
          if (char_profile.height || char_profile.weight || char_profile.skin_color || char_profile.eye_color || char_profile.hair_color || char_profile.dist_marks) {
            this.set('show_biometrics', true);
          }
          if (char_profile.bio_details || char_profile.family_details) {
            this.set('show_bio_details', true);
          }
        }
      });
    }
  };
  var _default = _exports.default = (0, _AccessGenerics.AccessGenerics)({
    init,
    actions
  });
});