define("discourse/plugins/discourse-char-coordinator/lib/CharFormHandler", ["exports", "ember", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _ember, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CharFormHandler = void 0;
  const CharFormHandler = _ref => {
    let {
      init
    } = _ref;
    return _ember.default.Controller.extend({
      init,
      permissionsCheck(current_user) {
        if (!current_user) {
          this.set('not_logged_in', true);
          return;
        }

        // find out if the current user is Admin or Moderator
        if (current_user.admin) this.set('user_is_staff', true);
        if (current_user.moderator && this.siteSettings.dcc_allow_mods_access) this.set('user_is_staff', true);
      },
      fetchUserChars(current_user_id, char_list) {
        return (0, _ajax.ajax)('/chars_fetch_user/' + current_user_id, {
          type: "GET"
        }).then(response => {
          response.forEach(char => {
            char_list.pushObject(JSON.parse(char.value));
          });

          //console.log(char_list);
          char_list.sort(this.compareAlpha);
          return response;
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchAllChars(char_list) {
        return (0, _ajax.ajax)('/chars_fetch_all/', {
          type: "GET"
        }).then(response => {
          const values = response.map(char => JSON.parse(char.value));
          values.forEach(value => {
            char_list.pushObject(value);
          });
          return values;
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchRestrictedFields() {
        return (0, _ajax.ajax)('/genfetch_restricted_fields', {
          type: "GET"
        }).then(response => {
          return response;
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchCdirStatuses() {
        return (0, _ajax.ajax)('/genfetch_cdir_statuses', {
          type: "GET"
        }).then(response => {
          return response;
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchPlayerGroup() {
        return (0, _ajax.ajax)('/genfetch_player_group', {
          type: "GET"
        }).then(response => {
          return response;
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchFactionRanks() {
        //console.log("called fetchFactionRanks");

        return (0, _ajax.ajax)('/genfetch_faction_ranks', {
          type: "GET"
        }).then(response => {
          return response;
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchAllTrees() {
        (0, _ajax.ajax)('/trees_fetch_all', {
          type: "GET"
        }).then(response => {
          response.forEach(tree => {
            this.tree_list.pushObject(JSON.parse(tree.value));
          });
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchDiscourseTags() {
        return (0, _ajax.ajax)('/tags', {
          type: "GET"
        }).then(response => {
          let str = " ";
          response.tags.forEach(tag => {
            str += tag.text + " ";
          });
          return str;
        }).catch(_ajaxError.popupAjaxError);
      },
      makeCharTag(char_tag) {
        //console.log("calling makeCharTag on", char_tag);

        (0, _ajax.ajax)('/chars_make_tag/' + char_tag, {
          type: "PUT"
        });
      },
      fetchPlayerGroupUsers(player_group, user_list) {
        (0, _ajax.ajax)('/groups/' + player_group + '/members.json?asc=true&', {
          type: "GET"
        }).then(response => {
          const totalPages = response.meta.total / response.meta.limit;
          response.members.forEach(user => {
            user_list.pushObject(user);
          });

          // fracking pagination
          for (let i = 1; i < totalPages; i++) {
            (0, _ajax.ajax)('/groups/' + player_group + '/members.json?asc=true&offset=' + i * response.meta.limit, {
              type: "GET"
            }).then(res => {
              res.members.forEach(u => {
                user_list.pushObject(u);
              });
            });
          }
        }).catch(_ajaxError.popupAjaxError);
      },
      // limited by API to first 100 results; don't use
      // fetchDiscourseUsers(user_list) {
      //   ajax('/admin/users', { type: "GET" }).then(response => {
      //     console.log(response);
      //
      //     response.forEach(user => {
      //       user_list.pushObject(user);
      //     });
      //   }).catch(popupAjaxError);
      // },

      compareAlphaGroupNames(a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;else if (b.name.toLowerCase() < a.name.toLowerCase()) return 1;else return Math.sign(b.name - a.name);
      },
      compareAlpha(a, b) {
        const a_fchar = a.l_name.charAt(0);
        const b_fchar = b.l_name.charAt(0);
        const a_is_alpha = a_fchar.match(/[a-z]/i);
        const b_is_alpha = b_fchar.match(/[a-z]/i);
        if (!a_is_alpha && !b_is_alpha) return a.l_name.localeCompare(b.l_name);
        if (!a_is_alpha) return -1;
        if (!b_is_alpha) return 1;
        if (a.l_name.toLowerCase() < b.l_name.toLowerCase()) return -1;else if (b.l_name.toLowerCase() < a.l_name.toLowerCase()) return 1;else {
          if (!a.f_name) return 1;else if (!b.f_name) return -1;else {
            if (a.f_name != b.f_name) {
              return Math.sign(b.f_name - a.f_name);
            }
          }
        }
        return 0;
      },
      fetchAllRulesets() {
        return (0, _ajax.ajax)('/rulesets_fetch_all', {
          type: "GET"
        }).then(response => {
          //console.log(response);

          let ruleset_list = [];
          response.forEach(ruleset => {
            ruleset_list.pushObject(JSON.parse(ruleset.value));
          });
          return ruleset_list;
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchGroupChars(group_id) {
        return (0, _ajax.ajax)('/fetch_group_chars/' + group_id, {
          type: "GET"
        }).then(response => {
          let chars_list = [];
          response.group_chars.forEach(char => {
            chars_list.pushObject(char);
          });
          return chars_list;
        }).catch(_ajaxError.popupAjaxError);
      },
      actions: {
        createChar() {
          let char_id = this.get('id');
          //console.log("Saving to store, char_id =", char_id);

          if (!this.get('user_id')) {
            // output failure message
            let fail_msg = $('#dcc_plugin div.page div.save_response span');
            fail_msg.show();
            fail_msg.html("User ID field is required.");
            fail_msg.css("color", "red");
            fail_msg.fadeOut(3000);
            return;
          }

          // if one's not provided, create a random ID for character
          if (!char_id) {
            const a_str = 'abcdefghijklmnopqrstuvwxyz';

            // two letters first
            let a = a_str.charAt(Math.floor(Math.random() * 26)) + a_str.charAt(Math.floor(Math.random() * 26));

            // 3 numbers, then 4 numbers
            let b = Math.random().toString(10).substring(2, 6);
            let c = Math.random().toString(10).substring(2, 5);

            // then three letters
            let d = a_str.charAt(Math.floor(Math.random() * 26)) + a_str.charAt(Math.floor(Math.random() * 26)) + a_str.charAt(Math.floor(Math.random() * 26));
            char_id = a + b + c + d;
          }

          // check if char ID already exists, fail if it does
          const redundant_char_id = this.char_list.find(c => {
            if (char_id.toLowerCase() == c.id.toLowerCase() && char_id.toLowerCase() != this.former_char_id.toLowerCase()) {
              let fail_msg = $('#dcc_plugin div.page div.save_response span');
              fail_msg.show();
              fail_msg.html("Char ID is already taken, please select another.");
              fail_msg.css("color", "red");
              fail_msg.fadeOut(3000);
              return true;
            }
          });
          if (redundant_char_id) return;

          // save the char_id now, so we don't accidentally
          // create multiples when spamming the save button
          this.set('id', char_id);
          const char_data = this.store.createRecord('char', {
            id: char_id,
            user_id: this.get('user_id'),
            status: this.get('status'),
            discourse_tag: this.get('discourse_tag'),
            game_handle: this.get('game_handle'),
            faction: this.get('faction'),
            tree_id: this.get('tree_id'),
            node_id: this.get('node_id'),
            redirect_url: this.get('redirect_url'),
            rank: this.get('rank'),
            f_name: this.get('f_name'),
            m_name: this.get('m_name'),
            l_name: this.get('l_name'),
            service_num: this.get('service_num'),
            job_title: this.get('job_title'),
            dob: this.get('dob'),
            pob: this.get('pob'),
            species: this.get('species'),
            gender: this.get('gender'),
            height: this.get('height'),
            weight: this.get('weight'),
            skin_color: this.get('skin_color') || null,
            eye_color: this.get('eye_color') || null,
            hair_color: this.get('hair_color') || null,
            dist_marks: this.get('dist_marks'),
            bio_details: this.get('bio_details'),
            family_details: this.get('family_details'),
            career_details: this.get('career_details'),
            photo_link: this.get('photo_link'),
            misc: this.get('misc')
          });
          char_data.save().then(result => {
            //console.log("result =", result);

            // output success message
            let success_msg = $('#dcc_plugin div.page div.save_response span');
            success_msg.show();
            success_msg.html("Save successful. You may need to refresh to see the results.");
            success_msg.css("color", "green");
            success_msg.fadeOut(5000);

            // save specific fields for former state comparisons
            this.set('former_char_id', this.get('id'));
            this.set('former_discourse_tag', this.get('discourse_tag'));

            // check if we should create tag also
            if (this.siteSettings.dcc_enable_tags_integration) this.makeCharTag(result.payload.discourse_tag);

            // check if char tag needs to be moved 
            // to a different status group
            // TODO 

            // compare new values against stored old ones
            // to see if we need to update group memberships
            // if (group integrations active)
            if (this.siteSettings.dcc_enable_groups_integration) {
              if (this.get('tree_id') != this.get('former_tree_id') || this.get('node_id') != this.get('former_node_id')) {
                //console.log("Node or Tree has changed. Run rulesets.");
                (0, _ajax.ajax)('/run_all_rulesets/', {
                  type: "PUT"
                }).catch(_ajaxError.popupAjaxError);
                this.set('former_tree_id', this.get('tree_id'));
                this.set('former_node_id', this.get('node_id'));
              }
            }

            // also update the app state
            if (this.get('id')) {
              this.char_list.forEach((char, index) => {
                if (char.id == result.payload.id) this.char_list[index] = result.payload;
              });
            }
          }).catch(console.error);
          // could use a real error here.
        },
        loadChar(index) {
          let char = -1;
          if (index == -1) {
            // reset everything to empty
            char = {
              "id": -1
            };
            $('#dcc_plugin #my-chars form').hide();
          } else {
            char = this.filtered_char_list[index];
            $('#dcc_plugin #my-chars form').show();
          }

          //console.log("Save some current field values as 'former' for comparisons later.");
          this.set('former_char_id', char.id);
          this.set('former_discourse_tag', char.discourse_tag);
          this.set('former_tree_id', char.tree_id);
          this.set('former_node_id', char.node_id);
          this.set('id', char.id);
          this.set('user_id', char.user_id);
          this.set('status', char.status);
          this.set('discourse_tag', char.discourse_tag);
          this.set('game_handle', char.game_handle);
          this.set('faction', char.faction);
          $('label[name="faction"] input').val(char.faction);
          this.set('rank', char.rank);
          $('label[name="rank"] input').val(char.rank);
          this.set('tree_id', char.tree_id);
          $('label[name="tree_id"] input').val(char.tree_id);
          this.set('node_id', char.node_id);
          $('label[name="node_id"] input').val(char.node_id);
          this.set('redirect_url', char.redirect_url);
          this.set('f_name', char.f_name);
          this.set('m_name', char.m_name);
          this.set('l_name', char.l_name);
          this.set('service_num', char.service_num);
          this.set('job_title', char.job_title);
          this.set('dob', char.dob);
          this.set('pob', char.pob);
          this.set('species', char.species);
          this.set('gender', char.gender);
          this.set('height', char.height);
          this.set('weight', char.weight);
          this.set('skin_color', char.skin_color);
          this.set('eye_color', char.eye_color);
          this.set('hair_color', char.hair_color);
          this.set('dist_marks', char.dist_marks);
          this.set('bio_details', char.bio_details);
          this.set('family_details', char.family_details);
          this.set('career_details', char.career_details);
          this.set('photo_link', char.photo_link);
          this.set('misc', char.misc);
        },
        // only ever called from char-lookup
        deleteChar() {
          // alert/confirm on delete  :roll:
          if (confirm("You sure? This action cannot be undone.")) {
            const char_index = $('#char-lookup select').val();
            const char_id = this.char_list[char_index].id;
            (0, _ajax.ajax)('/chars/' + char_id, {
              type: "DELETE"
            }).then(response => {
              this.char_list.removeObject(this.char_list[char_index]);
            }).catch(_ajaxError.popupAjaxError);
          }
        },
        loadRanksDropdown(faction) {
          this.set('faction', faction);
          this.set('rank_list', this.full_rank_list[faction]);
        },
        setThisRank(rank) {
          this.set('rank', rank);
        },
        loadNodeIdDropdown(tree_id) {
          this.set('tree_id', tree_id); // for app state
          this.set('node_list', []);
          if (tree_id == "" || tree_id == -1) return;
          let active_tree = -1;
          this.tree_list.forEach(tree => {
            if (tree.id == tree_id) active_tree = tree;
          });
          const tree_nodes = Object.values(JSON.parse(active_tree.map));
          tree_nodes.forEach(node => {
            this.node_list.pushObject(node);
          });
        },
        setThisNodeId(node_id) {
          // ffs. so lame.
          this.set('node_id', node_id);
        },
        verifyCharTag(char_tag) {
          if (char_tag != "") {
            if (this.tag_list_str.indexOf(" " + char_tag.toLowerCase() + " ") > -1) {
              if (char_tag == this.former_discourse_tag) {
                $('#discourse_tag_validator').empty();
              } else {
                $('#discourse_tag_validator').css('color', 'red');
                $('#discourse_tag_validator').html("WARNING -- Tag already exists!");
              }
            } else {
              $('#discourse_tag_validator').css('color', 'green');
              $('#discourse_tag_validator').html("Tag is free!");
            }
          } else {
            $('#discourse_tag_validator').empty();
          }
        },
        verifyCharId(char_id) {
          if (char_id != "") {
            const exists = this.char_list.find(char => {
              if (char_id == char.id) return true;
            });
            if (exists) {
              if (char_id == this.former_char_id) {
                $('#char_id_validator').empty();
              } else {
                $('#char_id_validator').css('color', 'red');
                $('#char_id_validator').html("WARNING -- ID already exists!");
                return true;
              }
            } else {
              $('#char_id_validator').css('color', 'green');
              $('#char_id_validator').html("ID is free!");
            }
          } else {
            $('#char_id_validator').empty();
          }
          return false;
        },
        // only called from my-chars
        statusFilter(status) {
          if (status == -1) this.set("filtered_char_list", this.char_list);else {
            this.set("filtered_char_list", this.char_list.filter(char => char.status === status));
          }
          this.char = {
            "id": -1
          };
          $('#dcc_plugin #my-chars form').hide();
        },
        // only called from create-char
        clearForm() {
          $('#create-char input').each(function () {
            $(this).val("");
          });
          this.set('id', "");
        },
        clearColorField(field) {
          this.set(field, null);
          return;
        },
        filterUserList(value) {
          const filter = value.toLowerCase();
          $('option').each(function () {
            let content = $(this).html();
            if (content.toLowerCase().indexOf(filter) > -1) $(this).show();else $(this).hide();
          });
        },
        loadUserId(value) {
          this.set('user_id', value);
        },
        // only called from char-lookup
        filterCharList(value) {
          const filter = value.toLowerCase();
          $('option').each(function () {
            let content = $(this).html();
            if (content.toLowerCase().indexOf(filter) > -1) $(this).show();else $(this).hide();
          });
        },
        // only called from directory
        filterDirectory(value) {
          const filter = value.toLowerCase();
          $('dd').each(function () {
            let content = $(this).html();
            if (content.toLowerCase().indexOf(filter) > -1) $(this).show();else $(this).hide();
          });
        },
        loadGroup(index) {
          this.set('group_loaded', []);
          if (index > -1) {
            (0, _ajax.ajax)('/fetch_group_chars/' + this.group_list_ids[index], {
              type: "GET"
            }).then(response => {
              const char_array = [];
              response.group_chars.forEach(char => {
                const char_status = char.status.toLowerCase();
                if (!this.cdir_statuses.includes(char_status)) char_array.push(char);
              });
              char_array.sort(this.compareAlpha);
              this.set('group_loaded', char_array);
            }).catch(_ajaxError.popupAjaxError);
          }
        }
      }
    });
  };
  _exports.CharFormHandler = CharFormHandler;
});