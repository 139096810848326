define("discourse/plugins/discourse-char-coordinator/discourse/models/tree", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rest.default.extend({
    // Required when sending PUT requests via Discourse's store
    updateProperties() {
      return this.getProperties('id', 'name', 'about', 'map');
    }
  });
});