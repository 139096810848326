define("discourse/plugins/discourse-char-coordinator/lib/AccessGenerics", ["exports", "ember", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _ember, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AccessGenerics = void 0;
  const AccessGenerics = _ref => {
    let {
      init,
      actions
    } = _ref;
    return _ember.default.Controller.extend({
      init,
      queryParams: [{
        'v': {
          scope: 'controller'
        }
      }],
      permissionsCheck(current_user) {
        if (!current_user) return;

        // find out if the current user is Admin or Moderator
        if (current_user.admin) this.set('user_is_staff', true);
        if (current_user.moderator && this.siteSettings.dcc_allow_mods_access) this.set('user_is_staff', true);
      },
      sanitizeForHTML(str) {
        const sanitized = str.replace(/[ ,'\/#!$%\^&\*;:{}=`~()]/g, "");
        return sanitized;
      },
      fetchProfileCss() {
        //console.log("called generics:fetchProfileCss");
        return (0, _ajax.ajax)('/genfetch_profile_css', {
          type: "GET"
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchOrgchartCss() {
        //console.log("called generics:fetchOrgchartCss");
        return (0, _ajax.ajax)('/genfetch_orgchart_css', {
          type: "GET"
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchRestrictedFields() {
        //console.log("called generics:fetchRestrictedFields");
        return (0, _ajax.ajax)('/genfetch_restricted_fields', {
          type: "GET"
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchPlayerGroup() {
        //console.log("called generics:fetchPlayerGroup");
        return (0, _ajax.ajax)('/genfetch_player_group', {
          type: "GET"
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchCdirStatuses() {
        //console.log("called generics:fetchRestrictedFields");
        return (0, _ajax.ajax)('/genfetch_cdir_statuses', {
          type: "GET"
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchPluginIntro() {
        //console.log("called generics:fetchPluginIntro");
        return (0, _ajax.ajax)('/genfetch_plugin_intro', {
          type: "GET"
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchPluginStaffIntro() {
        //console.log("called generics:fetchPluginStaffIntro");
        return (0, _ajax.ajax)('/genfetch_plugin_staff_intro', {
          type: "GET"
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchFactionRanks() {
        //console.log("called generics:fetchFactionRanks");
        return (0, _ajax.ajax)('/genfetch_faction_ranks', {
          type: "GET"
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchChar(char_id) {
        return (0, _ajax.ajax)('/cprofile/view/' + char_id, {
          type: "GET"
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchUsernameFromId(user_id) {
        //console.log("called generics:fetchProfileCss");
        return (0, _ajax.ajax)('/cprofile/u/' + user_id, {
          type: "GET"
        }).catch(_ajaxError.popupAjaxError);
      },
      fetchTree(tree_id) {
        return (0, _ajax.ajax)('/orgchart/view/' + tree_id, {
          type: "GET"
        }).catch(_ajaxError.popupAjaxError);
      },
      actions
    });
  };
  _exports.AccessGenerics = AccessGenerics;
});