define("discourse/plugins/discourse-char-coordinator/discourse/controllers/orgchart", ["exports", "discourse/plugins/discourse-char-coordinator/lib/TreeHandler", "discourse/models/user", "@ember/template"], function (_exports, _TreeHandler, _user, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const init = function () {
    this._super();
    this.fetchOrgchartCss().then(response => {
      this.set('orgchart_css', response);
    });
    if (_user.default.current()) this.set('user_logged_in', true);
  };
  const actions = {
    initOrgchart(tree_id) {
      this.set('tree_id', tree_id);

      // get rank list for adding priorities to char nodes later
      this.fetchFactionRanks().then(response => {
        this.set('full_rank_list', JSON.parse(response));
        Object.entries(this.full_rank_list).forEach(_ref => {
          let [faction_name, faction] = _ref;
          let ranks = Object.values(faction);
          let namedKeys = {};
          ranks.forEach(rank => {
            namedKeys[rank.abbr] = rank;
          });
          this.full_rank_list[faction_name] = namedKeys;
        });
      });
      Promise.all([this.fetchTree(tree_id), this.fetchTreePersonnel(tree_id)]).then(_ref2 => {
        let [tree, tree_personnel] = _ref2;
        this.set('tree_about', (0, _template.htmlSafe)(tree.about));
        const map_json = JSON.parse(tree.map);
        const map_nodes = Object.values(JSON.parse(tree.map));
        let child_map = this.reverseAncestry(map_nodes);

        // personnel next
        tree_personnel.forEach(char => {
          let char_json = JSON.parse(char.value);
          let parent_id = char_json.node_id;
          if (parent_id != "") {
            if (child_map[parent_id] == null) child_map[parent_id] = [];

            // add some fields to this personnel node
            const faction = this.full_rank_list[char_json.faction];
            if (faction && faction[char_json.rank]) char_json.priority = faction[char_json.rank].priority;else char_json.priority = -1;

            // can we look up the node_co?
            if (char_json.node_id) {
              const node_id = char_json.node_id;
              if (map_json[node_id]) {
                const node_co_field = map_json[node_id].node_co;
                if (node_co_field != -1) {
                  const node_co_array = node_co_field.split(",");
                  node_co_array.forEach((node_co, index) => {
                    if (node_co == char_json.id) {
                      char_json.node_co = "true";
                      char_json.node_co_priority = index;
                    }
                  });
                }
              }
            }
            child_map[parent_id].push(char_json);
          }
        });
        let complete_tree_map = this.mergeMappings(map_nodes, child_map);
        console.log(complete_tree_map);
        const tree_roots = this.getTreeRoots(complete_tree_map);
        this.set('tree_roots', tree_roots);
        //console.log(this.tree_roots);
      });
    },
    expandAll() {
      $('.item_char').show();
      $('div[name].item_node').next().show();
    },
    collapseAll() {
      $('.item_char').hide();
    },
    toggleHeading(item) {
      if (item.parent) {
        let selector_base = 'div[name="' + item.id + '"].item_node';
        $(selector_base).next().toggle(200);
        $(selector_base).next().find('.item_char').show();
      } else {
        let selector_base = 'div[name="' + item.id + '"]';
        $(selector_base).toggle(200);
      }
    },
    toggleMeta(item, state) {
      let selector_base = 'div[name="' + item.id + '"] span';
      if (item.game_handle) {
        if (state == "on") {
          $(selector_base + ".l_name").addClass('meta');
          $(selector_base + ".rem_name").addClass('meta');
          $(selector_base + ".game_handle").removeClass('meta');
        } else {
          $(selector_base + ".l_name").removeClass('meta');
          $(selector_base + ".rem_name").removeClass('meta');
          $(selector_base + ".game_handle").addClass('meta');
        }
      }
    }
  };
  var _default = _exports.default = (0, _TreeHandler.TreeHandler)({
    init,
    actions
  });
});