define("discourse/plugins/discourse-char-coordinator/discourse/dcc-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /*
  * route -> url/path
  */

  function _default() {
    this.route('dcc', {
      path: '/dcc'
    });
    this.route('my-chars', {
      path: '/dcc/my-chars'
    });
    this.route('create-char', {
      path: '/dcc/create-char'
    });
    this.route('char-lookup', {
      path: '/dcc/char-lookup'
    });
    this.route('manage-tree', {
      path: '/dcc/manage-tree'
    });
    this.route('edit-ruleset', {
      path: '/dcc/edit-ruleset'
    });
    this.route('profile', {
      path: '/cprofile'
    });
    this.route('orgchart', {
      path: '/orgchart'
    });
    this.route('directory', {
      path: '/directory'
    });
  }
});