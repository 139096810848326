define("discourse/plugins/discourse-char-coordinator/discourse/models/char", ["exports", "discourse/models/rest", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _rest, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rest.default.extend({
    // Required when sending PUT requests via Discourse's store
    updateProperties() {
      return this.getProperties('id', 'user_id', 'status', 'discourse_tag', 'game_handle', 'faction', 'rank', 'tree_id', 'node_id', 'redirect_url', 'f_name', 'm_name', 'l_name', 'service_num', 'job_title', 'dob', 'pob', 'species', 'gender', 'height', 'weight', 'skin_color', 'eye_color', 'hair_color', 'dist_marks', 'dist_marks_cooked', 'bio_details', 'bio_details_cooked', 'family_details', 'family_details_cooked', 'career_details', 'career_details_cooked', 'photo_link', 'misc', 'misc_cooked');
    }
  });
});