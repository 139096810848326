define("discourse/plugins/discourse-char-coordinator/discourse/controllers/manage-tree", ["exports", "discourse/models/user", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/plugins/discourse-char-coordinator/lib/TreeHandler"], function (_exports, _user, _ajax, _ajaxError, _TreeHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const init = function () {
    this._super();
    this.permissionsCheck(_user.default.current());
    if (this.get('not_logged_in')) return;
    this.fetchAllTrees().then(response => {
      this.set('tree_list', response);
    });
  };
  const actions = {
    createTree() {
      let tree_id = this.get('id');

      // create a random ID for tree
      if (!tree_id) {
        let a = Math.random().toString(36).substring(2, 4);
        let b = Math.random().toString(36).substring(4, 6);
        tree_id = a + b;
      }
      if (!this.get('map')) {
        //console.log('new tree, start with a single node');
        this.set('map', "{\"0\": { \"id\": 0, \"name\": -1, \"parent\": null, \"node_co\": -1, \"priority\": 0, \"info_link\": \"#\", \"icon_link\": \"#\", \"station\": -1 }}");
      } else {
        // make sure to update ember app state!!
        this.set('map', $('#tree_map').val());
      }
      const tree_data = this.store.createRecord('tree', {
        id: tree_id,
        name: this.get('name'),
        about: this.get('about'),
        map: this.get('map')
      });
      tree_data.save().then(result => {
        let tree_exists = false;
        for (var i = 0; i < this.tree_list.length; i++) {
          if (this.tree_list[i].id == result.target.id) {
            this.tree_list.removeAt(i);
            this.tree_list.pushObject(result.target);
            tree_exists = true;
          }
        }
        if (!tree_exists) this.tree_list.pushObject(result.target);
      }).catch(console.error);
    },
    loadTree(tree) {
      this.set('id', tree.id);
      this.set('name', tree.name);
      this.set('about', tree.about);
      this.set('map', tree.map);
    },
    deleteTree(tree) {
      if (confirm("You sure? This action cannot be undone.")) {
        (0, _ajax.ajax)('/trees/' + tree.id, {
          type: "DELETE"
        }).then(response => {
          this.tree_list.removeObject(tree);
        }).catch(_ajaxError.popupAjaxError);
      }
    },
    renderTreeMap() {
      //console.log("in renderTreeMap");

      const map_json = JSON.parse($('#tree_map').val());
      let map_nodes = Object.values(map_json);
      this.set('highest_node_index', -1);
      map_nodes.forEach(node => {
        if (this.highest_node_index < node.id) this.highest_node_index = node.id;
      });
      //console.log("highest_node_index", this.highest_node_index);

      let child_map = this.reverseAncestry(map_nodes);
      let complete_tree_map = this.mergeMappings(map_nodes, child_map);
      //console.log(complete_tree_map);

      const tree_roots = this.getTreeRoots(complete_tree_map);
      this.set('current_tree_rendered', tree_roots);
      //console.log(this.current_tree_rendered);

      this.set('current_tree_as_list', complete_tree_map);
    },
    loadTreeNode(tree_node) {
      this.set('current_node_rendered', []);
      this.current_node_rendered.pushObject(tree_node);
    },
    newTreeNode() {
      //console.log("creating new node");
      let map_json = JSON.parse($('#tree_map').val());
      const new_node_id = this.highest_node_index + 1;
      this.set('highest_node_index', new_node_id);
      const new_node = {
        "id": new_node_id,
        "name": 'untitled',
        "parent": null,
        "station": -1,
        "node_co": -1,
        "info_link": "",
        "icon_link": "",
        "desc": -1,
        "start_collapsed": false
      };
      map_json[new_node_id] = new_node;
      $('#tree_map').val(JSON.stringify(map_json));
    },
    saveTreeNode(tree_node) {
      //console.log("saving this node");

      const map_json = JSON.parse($('#tree_map').val());
      let this_node = map_json[tree_node.id];
      this_node.name = tree_node.name;
      this_node.station = tree_node.station;
      this_node.info_link = tree_node.info_link;
      this_node.icon_link = tree_node.icon_link;
      this_node.parent = tree_node.parent;
      this_node.node_co = tree_node.node_co;
      this_node.priority = tree_node.priority;
      this_node.desc = tree_node.desc;
      this_node.start_collapsed = tree_node.start_collapsed;
      map_json[tree_node.id] = this_node;
      //console.log('revised map_json', map_json);

      $('#tree_map').val(JSON.stringify(map_json));
    },
    deleteTreeNode(tree_node) {
      const map_json = JSON.parse($('#tree_map').val());
      delete map_json[tree_node.id];
      $('#tree_map').val(JSON.stringify(map_json));
    }
  };
  var _default = _exports.default = (0, _TreeHandler.TreeHandler)({
    init,
    actions
  });
});