define("discourse/plugins/discourse-char-coordinator/discourse/controllers/char-lookup", ["exports", "discourse/plugins/discourse-char-coordinator/lib/CharFormHandler", "discourse/models/user"], function (_exports, _CharFormHandler, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const init = function () {
    this._super();
    this.permissionsCheck(_user.default.current());
    if (this.get('not_logged_in')) return;
    this.set('char_list', []);
    this.fetchAllChars(this.char_list);
    this.set('filtered_char_list', this.char_list);
    this.set('node_list', []);
    this.set('tree_list', []);
    this.fetchAllTrees(this.tree_list);
    this.fetchFactionRanks().then(response => {
      if (Object.keys(response).length > 0) {
        this.set('full_rank_list', JSON.parse(response));
        this.set('faction_list', Object.keys(this.full_rank_list));
      }
    });
    if (this.siteSettings.tagging_enabled) {
      this.fetchDiscourseTags().then(response => {
        this.set('tag_list_str', response);
      });
    }
  };
  var _default = _exports.default = (0, _CharFormHandler.CharFormHandler)({
    init
  });
});