define("discourse/plugins/discourse-char-coordinator/discourse/helpers/field-is-restricted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(field_name, named_args) {
    if (named_args.admin)
      // this is an admin form, override restriction
      return false;
    if (named_args.list) {
      if (named_args.list.indexOf(field_name) > -1) return true;
    }
    return false;
  }
});