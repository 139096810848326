define("discourse/plugins/discourse-char-coordinator/discourse/controllers/directory", ["exports", "discourse/plugins/discourse-char-coordinator/lib/CharFormHandler", "discourse/models/user"], function (_exports, _CharFormHandler, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const init = function () {
    this._super();
    this.permissionsCheck(_user.default.current());
    if (this.get('not_logged_in')) return;
    this.set('cdirectory', []);
    this.set('char_list', []);
    Promise.all([this.fetchCdirStatuses(), this.fetchAllChars(this.char_list)]).then(_ref => {
      let [res1, res2] = _ref;
      this.set('cdir_statuses', res1);
      this.char_list.sort(this.compareAlpha);
      const dir_obj = {};
      this.char_list.forEach(char => {
        console.log(char);
        const {
          l_name = ''
        } = char;
        const fletter = l_name.charAt(0).toLowerCase();
        const isLetter = fletter.match(/[a-z]/i);
        const key = isLetter ? fletter : '#';
        if (!dir_obj[key]) dir_obj[key] = [];

        // only include chars w/accepted status
        const char_status = char.status.toLowerCase();
        if (!this.cdir_statuses.includes(char_status)) dir_obj[key].pushObject(char);
      });
      this.set('alphabet', "#abcdefghijklmnopqrstuvwxyz".split(''));
      this.alphabet.forEach(letter => {
        this.cdirectory.pushObject(dir_obj[letter] || []);
      });
    });
    this.set('group_list', []);
    this.set('group_list_ids', []);
    this.set('group_chars', []);
    this.fetchAllRulesets().then(response => {
      response.sort(this.compareAlphaGroupNames);
      response.forEach(ruleset => {
        this.group_list.pushObject(ruleset.name);
        this.group_list_ids.pushObject(ruleset.id);
      });
    });
    if (this.siteSettings.tagging_enabled) {
      this.fetchDiscourseTags().then(response => {
        this.set('tag_list_str', response);
      });
    }
  };
  var _default = _exports.default = (0, _CharFormHandler.CharFormHandler)({
    init
  });
});