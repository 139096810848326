define("discourse/plugins/discourse-char-coordinator/discourse/controllers/components/render-children-nodes-only", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    init() {
      this._super();
    },
    actions: {
      loadTreeNode(tree_node) {
        this.attrs.loadTreeNode(tree_node);
      }
    }
  });
});