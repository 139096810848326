define("discourse/plugins/discourse-char-coordinator/discourse/helpers/sanitize-for-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(str) {
    const sanitized = "dcc_" + (str || "").replace(/[ ,'\/#!$%\^&\*;:{}=`~()]/g, "");
    return sanitized;
  }
});